/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
/* eslint-disable no-secrets/no-secrets */
import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import NeuroRoomJPG from '@assets/images/neuro-room.jpg';
import NeuroRoomMobileJPG from '@assets/images/neuro-room-mobile.jpg';
import HeroImage from '~/components/organisms/HeroImage/HeroImage';
import Header from '~/components/molecules/Header/Header';
import Footer from '~/components/molecules/Footer/Footer';
import { useTranslationContext } from '~/pages/terminvereinbarung';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/arrange-appointment';

const AppointmentPage: React.FC = () => {
  const [menu, setMenu] = useState(false);
  const scriptElement = useRef(null);
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  const clickHandler = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    const scriptComponent = document.createElement('script');
    scriptComponent.src =
      'https://www.jameda.de/api/otb-widget/?refid=81546596_1&version=1';
    scriptComponent.async = true;
    scriptElement.current.appendChild(scriptComponent);
  });

  return (
    <>
      <Container>
        <Header
          clickHandle={clickHandler}
          enLink="/en/arrange-appointment/"
          deLink="/terminvereinbarung/"
        />
        <HeroImage
          src={NeuroRoomJPG}
          alt="Neurologie Praxisraum"
          menu={menu}
          srcmobile={NeuroRoomMobileJPG}
        />
        <Container2>
          <ContainerSmall>
            <div
              dangerouslySetInnerHTML={{
                __html: t('AppointmentHeading') || tEn('AppointmentHeading'),
              }}
            />
            <Jameda ref={scriptElement} />
            <Jameda id="jam-ota-info_81546596_1" />
          </ContainerSmall>
        </Container2>
        <Footer t={t} />
      </Container>
    </>
  );
};

const Container = styled.article`
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1200px) {
    width: 1250px;
  }
`;

const Container2 = styled.div`
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1200px) {
    width: 1250px;
  }
`;

const ContainerSmall = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;

  @media (min-width: 1200px) {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;

    h1,
    h2,
    p {
      margin-right: 2rem;
    }
  }

  li {
    margin-right: 2rem;
    list-style-position: outside;
  }

  p {
    font-size: var(--primary-font-size);
    line-height: 1.5;
  }
`;

const Jameda = styled.div`
  margin-left: 2rem;
  margin-right: 2rem;
`;

export default AppointmentPage;
